<script setup lang="ts">
  // Idioma
  const { locale } = useI18n()

  // Config
  const runtimeConfig = useRuntimeConfig()

  // Props
  defineProps({
    info: Object,
    width: String
  })
</script>
<template>
  <div class="mb-8">
    <p class="text-lg font-bold pb-2 w-fit bg-clip-text text-transparent bg-gradient-to-r from-byhours-500 to-pink-500">
      {{ info?.smallText }}
    </p>
    <h2 :class="['text-4xl', 'md:text-7xl', width]">
      {{ info?.titulo }}
    </h2>
    <p class="mt-4" v-if="info?.texto">
      {{ info?.texto }}
    </p>
  </div>
  <div class="content">
    <slot></slot>
  </div>
  <div class="mt-8" v-if="info?.button">
    <UButton
        color="black"
        :to="getButtonUrl(info?.button)"
        target="_blank"
        :aria-label="info?.button?.label"
        size="xl"
        :label="info?.button?.label"
      >
    </UButton>
  </div>
</template>